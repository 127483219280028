<script setup>
import {favourites} from '@teemill/common/classes';
import {formatUrl, viewportSize} from '@teemill/common/helpers';
import {
  faPerson,
  faPersonDress,
  faChild,
  faFamily,
} from '@fortawesome/pro-light-svg-icons';
import VueCookie from 'vue-cookie';
import {startCase} from '@teemill/utilities';

import {computed, ref, nextTick, watch} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';

const store = useStore();

const props = defineProps({
  products: Array,
  maxColorSwatches: {
    type: Number,
    default: 5,
  },
  podHorizontalAlignment: String,
});

const categoryFilterCookieName = 'shopping-category-filter';

const activeCategoryFilter = ref();

const availableGenders = computed(() => {
  const genders = new Set(props.products.map(product => product.gender));
  return [...genders];
});

const availableAgeGroups = computed(() => {
  const ageGroups = new Set(props.products.map(product => product.ageGroup));
  return [...ageGroups];
});

const containsMens = computed(() => availableGenders.value.includes('male'));
const containsWomens = computed(() =>
  availableGenders.value.includes('female')
);
const containsAdults = computed(() =>
  availableAgeGroups.value.includes('adults')
);
const containsKids = computed(() => availableAgeGroups.value.includes('kids'));

const showCategoryFilterPicker = computed(() => {
  // only show category filter picker on marketplace division
  // TODO: Remove this check in future
  if (store.state.subdomain.division !== 223533) {
    return false;
  }

  return (
    (containsMens.value && containsWomens.value) ||
    (containsKids.value && containsAdults.value)
  );
});

const categoryFilterTabs = [
  {
    title: 'Mens',
    code: 'gender:male',
    icon: faPerson,
    condition: containsMens.value,
  },
  {
    title: 'Womens',
    code: 'gender:female',
    icon: faPersonDress,
    condition: containsWomens.value,
  },
  {
    title: 'Kids',
    code: 'ageGroup:kids',
    icon: faChild,
    condition: containsKids.value,
  },
  {
    title: 'All',
    code: 'everything',
    icon: faFamily,
    condition: true,
  },
];

const activeCategoryFilterTabs = computed(() => {
  let activeTabs = categoryFilterTabs.filter(tab => tab.condition);

  if (viewportSize.isSmaller('md')) {
    activeTabs = activeTabs.map(tab => ({
      title: tab.title,
      code: tab.code,
    }));
  }

  return activeTabs;
});

function onChangeCategoryFilter(e) {
  setNewCategoryFilter('nothing');

  nextTick(() => {
    setNewCategoryFilter(e, true);
  });
}

const renderLimit = ref(4);

const filteredProducts = computed(() => {
  if (!showCategoryFilterPicker.value && !('requestIdleCallback' in window)) {
    return props.products;
  }

  let products = [];
  switch (activeCategoryFilter.value) {
    case 'gender:male':
      products = props.products.filter(product => product.gender === 'male');
      break;
    case 'gender:female':
      products = props.products.filter(product => product.gender === 'female');
      break;
    case 'ageGroup:kids':
      products = props.products.filter(product => product.ageGroup === 'kids');
      break;
    case 'nothing':
      return [];
    case 'everything':
    default:
      products = props.products;
  }

  if (!products.length) {
    setNewCategoryFilter('everything');
    return props.products;
  }

  return products.slice(0, renderLimit.value);
});

async function attemptToFavourite(identifier) {
  try {
    await this.favourites.add(identifier);
  } catch (error) {
    snackbar.error(error.message);
  }
}

const route = useRoute();

watch(
  props.products,
  () => {
    // we have to wait for the product list to be initialised before
    // applying the route query param
    if (route.query.catalog) {
      switch (route.query.catalog) {
        case 'mens':
          setNewCategoryFilter('gender:male', true);
          break;
        case 'womens':
          setNewCategoryFilter('gender:female', true);
          break;
        case 'kids':
          setNewCategoryFilter('ageGroup:kids', true);
          break;
        default:
          setNewCategoryFilter('everything');
          break;
      }
    }
  },
  {immediate: true}
);

function setNewCategoryFilter(filterValue, remember = false) {
  if (validateCategoryFilter(filterValue)) {
    activeCategoryFilter.value = filterValue;

    if (remember) {
      VueCookie.set(categoryFilterCookieName, filterValue);
    }
  } else {
    activeCategoryFilter.value = 'everything';
  }
}

// check if the chosen filter is applicable to this collection
function validateCategoryFilter(filterValue) {
  switch (filterValue) {
    case 'gender:male':
      return containsMens.value;
    case 'gender:female':
      return containsWomens.value;
    case 'ageGroup:kids':
      return containsKids.value;
    default:
      return true;
  }
}

function getLongProductName(product) {
  if (product.defaultColour) {
    return `${startCase(product.defaultColour)} ${product.name}`;
  }
  return product.name;
}

function getProductHref(product) {
  if (product.productHref) {
    return product.productHref;
  }

  const href = `/product/${product.urlName}/`;

  if (product.defaultColour) {
    return `${href}${product.defaultColour}`;
  }

  return href;
}

if (!route.query.catalog) {
  setNewCategoryFilter(VueCookie.get(categoryFilterCookieName) || 'everything');
}

function onIdle(callback) {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(callback, {
      timeout: 10000,
    });
  } else {
    setTimeout(callback, 100);
  }
}

function increaseRenderLimit() {
  renderLimit.value += 4;

  if (renderLimit.value < props.products.length) {
    onIdle(increaseRenderLimit);
  }
}

onIdle(increaseRenderLimit);

watch(activeCategoryFilter, () => {
  renderLimit.value = 4;
  onIdle(increaseRenderLimit);
});

watch(
  () => props.products?.length,
  () => {
    renderLimit.value = 4;
    onIdle(increaseRenderLimit);
  }
);

const activePrice = product => {
  if (product.isBundle) {
    return product.totalBundlePrice;
  }

  if (product.priceRange) {
    return Math.min(...product.priceRange);
  }

  return product.price;
};

const salePrice = product => {
  if (product.isBundle) {
    return product.price;
  }

  if (product.salePriceRange) {
    return Math.min(...product.salePriceRange);
  }

  return product.salePrice;
};

const colorSwatch = (product, color) => {
  const thumbnail = product?.colorThumbnails?.[color];

  if (!thumbnail) {
    return color;
  }

  let background;

  if (thumbnail?.type === 'image') {
    background = `url(${formatUrl(thumbnail.value)})`;
  } else if (thumbnail?.type === 'color') {
    background = thumbnail.value;
  }

  return {
    text: color,
    value: color,
    name: color,
    background: background,
  };
};
</script>

<template>
  <div class="section-product-list mb-8">
    <tml-tabs
      v-if="showCategoryFilterPicker"
      :value="activeCategoryFilter"
      :class="{'mobile-tabs': viewportSize.isSmaller('md')}"
      :tabs="activeCategoryFilterTabs"
      fill
      class="mb-4"
      @input="onChangeCategoryFilter"
    />
    <tml-grid :breakpoints="{xs: 2, sm: 2, lg: 4}">
      <div
        v-for="product in filteredProducts"
        :key="`${product.id}-${product.optionId || 0}`"
        v-memo="[product.id, product.collectionImage, podHorizontalAlignment]"
      >
        <tml-new-pod
          :title="product.name"
          :alt-text="product.collectionImageAlt || getLongProductName(product)"
          :title-size="viewportSize.isSmaller('md') ? 0 : 1"
          :overlay-icon="favourites.getIconForProduct(product.id)"
          :image="product.collectionImage"
          :href="getProductHref(product)"
          :price="activePrice(product)"
          :sale-price="salePrice(product)"
          lazy-load
          :image-src-set="[
            {width: 320, height: 337},
            {width: 480, height: 505},
            {width: 640, height: 674},
          ]"
          :horizontal-alignment="podHorizontalAlignment"
          @overlay-icon-clicked="attemptToFavourite(product.id)"
        >
          <template v-if="product.reviews?.count" #after-title>
            <div
              class="w-full gap-2 items-center cursor-pointer no-hover text-sm"
            >
              <tml-star-rating
                class="inline mr-1"
                :rating="product.reviews.rating"
                :max="5"
              />
            </div>
          </template>
        </tml-new-pod>
        <div class="relative min-h-[2rem]">
          <div
            v-if="product.colors?.length > 1"
            class="inset-0 flex flex-wrap gap-1 max-w-[16em]"
          >
            <div
              v-for="(color, index) in product.colors
                .slice(0, maxColorSwatches)
                .map(color => {
                  return typeof color === 'string' ? {name: color} : color;
                })"
              :key="color.name"
            >
              <tml-anchor
                :href="
                  color.href || `/product/${product.urlName}/${color.name}`
                "
              >
                <tml-color-square
                  class="w-4 !h-4 rounded border-[1px] border-solid border-gray-200 overflow-hidden"
                  :color="colorSwatch(product, color.name)"
                  role="button"
                  :title="color.name"
                  disable-tooltip
                />
              </tml-anchor>
            </div>
            <tml-anchor
              v-if="product.colors.length > maxColorSwatches"
              :href="`/product/${product.urlName}/`"
              class="text-xs sm:text-sm ml-1 !leading-none !text-[--tml-text-color] opacity-70"
            >
              +{{ product.colors.length - maxColorSwatches }}
            </tml-anchor>
          </div>
        </div>
      </div>
    </tml-grid>
  </div>
</template>
